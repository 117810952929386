import { unityBaseurl } from "src/apiConfig/config";

export const NetworkContextName = process.env.REACT_APP_NETWORK_NAME;
export const ACTIVE_NETWORK = Number(process.env.REACT_APP_ACTIVE_CHAIN);
export const tokenContract = process.env.REACT_APP_TOKEN_CONTRACT;
export const NftContractAddress = process.env.REACT_APP_NFT_CONTRACT;
export const mulltiCallAddress = process.env.REACT_APP_MULTICALL_ADDRESS;
export const adminAddress = process.env.REACT_APP_ADMIN_ADDRESS;
export const default_RPC_URL = process.env.REACT_APP_RPC_URL;

export const dateFormat = "MMMM Do YYYY, h:mm:ss a";

export const unityMountainURL = `${unityBaseurl}/Multiplayer/MountainTrack/index.html?name=`;
export const unitySpaceURL = `${unityBaseurl}/Multiplayer/SpaceTrack/index.html?name=`;
export const unityCityURL = `${unityBaseurl}/Multiplayer/CityTrack/index.html?name=`;
export const practiceCity = `${unityBaseurl}/Singleplayer/CityTrack/index.html?`;
export const practiceMountains = `${unityBaseurl}/Singleplayer/MountainTrack/index.html?`;
export const practiceSpace = `${unityBaseurl}/Singleplayer/SpaceTrack/index.html?`;

export const carSpecifications = [
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: " 20",
      steerHelper: "0.0",
      traction: "0.0",
      fullTorqueOnAllWheels: "1200",
      reverseTorque: "400",
      downForce: "80",
      topSpeed: "150",
      slipLimit: "0.3",
      brakeTorque: "15000",
      mass: "800",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "30",
      steerHelper: "0.2",
      traction: "0.3",
      fullTorqueOnAllWheels: "1800",
      reverseTorque: "550",
      downForce: "89",
      topSpeed: "166",
      slipLimit: " 0.4",
      brakeTorque: "16500",
      mass: "930",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "55",
      steerHelper: "0.6",
      traction: "0.4",
      fullTorqueOnAllWheels: "1950",
      reverseTorque: "752",
      downForce: "99",
      topSpeed: " 250",
      slipLimit: "0.5",
      brakeTorque: "19600",
      mass: "690",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "59",
      steerHelper: "0.8",
      traction: "0.3",
      fullTorqueOnAllWheels: "2500",
      reverseTorque: "800",
      downForce: "120",
      topSpeed: "250",
      slipLimit: "0.7",
      brakeTorque: "25000",
      mass: "1200",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "20",
      steerHelper: "0.0",
      traction: "0.0",
      fullTorqueOnAllWheels: "1200",
      reverseTorque: "400",
      downForce: "80",
      topSpeed: "150",
      slipLimit: "0.3",
      brakeTorque: "15000",
      mass: "800",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "30",
      steerHelper: "0.2",
      traction: "0.3",
      fullTorqueOnAllWheels: "1800",
      reverseTorque: "550",
      downForce: "89",
      topSpeed: "166",
      slipLimit: "0.4",
      brakeTorque: "16500",
      mass: "930",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "55",
      steerHelper: "0.6",
      traction: "0.4",
      fullTorqueOnAllWheels: "1950",
      reverseTorque: "752",
      downForce: "99",
      topSpeed: "250",
      slipLimit: "0.5",
      brakeTorque: "19600",
      mass: "690",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "59",
      steerHelper: "0.8",
      traction: "0.3",
      fullTorqueOnAllWheels: " 2500",
      reverseTorque: "800",
      downForce: "120",
      topSpeed: "250",
      slipLimit: "0.7",
      brakeTorque: "25000",
      mass: "1200",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "20",
      steerHelper: "0.0",
      traction: "0.0",
      fullTorqueOnAllWheels: "1200",
      reverseTorque: "400",
      downForce: "80",
      topSpeed: "150",
      slipLimit: "0.3",
      brakeTorque: "15000",
      mass: "800",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "30",
      steerHelper: "0.2",
      traction: "0.3",
      fullTorqueOnAllWheels: "1800",
      reverseTorque: "550",
      downForce: "89",
      topSpeed: "166",
      slipLimit: "0.4",
      brakeTorque: "16500",
      mass: "930",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "55",
      steerHelper: "0.6",
      traction: " 0.4",
      fullTorqueOnAllWheels: "1950",
      reverseTorque: "752",
      downForce: "99",
      topSpeed: "250",
      slipLimit: "0.5",
      brakeTorque: "19600",
      mass: "690",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
  {
    carLooks: {
      bodyNumber: "1",
      wheelNumber: "3",
      spoilerNumber: "4",
    },
    carValues: {
      maxSteerAngle: "59",
      steerHelper: "0.8",
      traction: "0.3",
      fullTorqueOnAllWheels: "2500",
      reverseTorque: "800",
      downForce: "120",
      topSpeed: "250",
      slipLimit: "0.7",
      brakeTorque: "25000",
      mass: "1200",
    },
    imageURLs: {
      carBodyURLs: {
        carBodyBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carBodyNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carBodyMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carWheelURLs: {
        carWheelBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carWheelNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carWheelMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
      carSpoilerURLs: {
        carSpoilerBaseURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054027/car_one_epic_BaseColor_zy47kb.png",
        carSpoilerNormalURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054023/car_one_epic_Normal_jur9px.png",
        carSpoilerMetallicURL:
          "https://res.cloudinary.com/rajnishmobiloitte/image/upload/v1660054022/car_one_epic_Metallic_zd3zdc.png",
      },
    },
  },
];

export const maxSpecifications = {
  maxSteerAngle: "70",
  steerHelper: "1.0",
  traction: "1.0",
  fullTorqueOnAllWheels: "3500",
  reverseTorque: "1000",
  downForce: "150",
  topSpeed: "350",
  slipLimit: "0.7",
  brakeTorque: "35000",
  mass: "1300",
};

export const NetworkDetails = [
  {
    chainId: ACTIVE_NETWORK == "56" ? "0x38" : "0x61",
    chainName: ACTIVE_NETWORK == "56" ? "Smart Chain" : "Smart Chain - Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [default_RPC_URL],
    blockExplorerUrls: [
      ACTIVE_NETWORK == "56"
        ? "https://bscscan.com/"
        : "https://testnet.bscscan.com/",
    ],
  },
];
