import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'
import { RxCross2 } from 'react-icons/rx'
import ButtonCircularProgress from './ButtonCircularProgress'

// Define custom styles
const useStyles = makeStyles(() => ({
  IconBox: {
    position: 'absolute',
    right: '0'
  },
  cancelBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      background: 'transparent'
    },
    '& svg': {
      color: '#fff',
      fontWeight: '700',
      fontSize: '35px',
      background: 'transparent'
    }
  },
  dialogPaper: {
    borderRadius: '20px'
  },
  dialougTitle: {
    '& .subTextWallet': {
      color: '#FFFFFF99',
      maxWidth: '100%',
      marginTop: '5px'
    }
  }
}))

export default function ConfirmationDialogBox ({
  openModal,
  handleClose,
  heading,
  description,
  HandleConfirm,
  isLoading,
  type
}) {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth
        classes={{ paper: classes.dialogPaper }} // Apply custom class to Paper
        open={openModal}
        keepMounted
        onClose={() => !isLoading && handleClose()}
      >
        <DialogTitle
          className='dislogTitleBox'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 16px 0px 16px'
          }}
        >
          {/* <IconButton
            disabled={isLoading}
            onClick={handleClose}
            className='closeButton'
          >
            <RxCross2 />
          </IconButton> */}
        </DialogTitle>
        <Typography
          variant='h4'
          className='ubuntu'
          style={{
            marginTop: '8px',
            marginBottom: '16px',
            textAlign: 'center'
          }}
        >
          {heading}
        </Typography>

        <DialogContent
          style={{
            textAlign: 'center'
            // padding: '0 16px 16px'
          }}
        >
          <Typography
            variant='h6'
            className='subTextWallet'
            style={{
              fontFamily: "'Sora', sans-serif",
              color: '#6b6b6b',
              marginBottom: '20px'
            }}
          >
            {description}
          </Typography>
        </DialogContent>

        {type !== 'ended' && (
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              paddingBottom: '16px'
            }}
          >
            <Button
              className='transparentbutton'
              style={{ minWidth: '133px' }}
              variant='contained'
              color='secondary'
              disabled={isLoading}
              onClick={handleClose}
            >
              Cancel
            </Button>

            {HandleConfirm && (
              <Button
                variant='contained'
                color='secondary'
                style={{ minWidth: '133px' }}
                onClick={HandleConfirm}
                disabled={isLoading}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
