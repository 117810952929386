import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiSelect: {
      icon: {
        top: "calc(50% - 12px)",
        color: "#00FFFF",
        right: "0px",
        position: "absolute",
        pointerEvents: "none",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgb(0 255 255)",
        border: "1px solid #00FFFF!important",
        padding: "0px",
        fontSize: "13px",
      },
      head: {
        color: "white",
        padding: "8px 15px",
        fontSize: "14px",
      },
      body: {
        color: "#fff",
        padding: "8px 15px",
      },
    },
    MuiTableRow: {
      root: {
        border: "1px solid #fff",
      },
    },

    MuiStepLabel: {
      label: {
        color: "#B1AFAF !important;",
        fontSize: "14px",
        "&.MuiStepLabel-alternativeLabel": {
          marginTop: "0px",
        },
        "&.MuiStepLabel-active": {
          color: "#00FFFF !important;",
        },
        // "& .MuiStepLabel-completed": {
        //
        // },
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        "@media(max-width:599.9px)": {
          borderTopStyle: "none",
          borderTopWidth: "0px",
        },
      },
    },
    MuiStepper: {
      root: {
        "@media(max-width:599.9px)": {
          display: "contents",
          padding: "24px",
        },
      },
    },
    MuiStep: {
      alternativeLabel: {
        flex: 1,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiGrid: {
      container: {
        width: "calc(100% + 24px) !important",
        display: "flex",
        flexWrap: "wrap",
        boxSizing: "border-box",
      },
    },

    MuiStepIcon: {
      text: {
        fill: "#000",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#000",
        },
        "&.MuiStepIcon-completed": {
          color: "#B1AFAF",
        },
        "&.MuiStepIcon-active": {
          color: "#00FFFF;",

          // "&:hover": {
          //   color: "red",
          // },
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "20px !important",
        height: "20px !important",
      },
    },

    MuiDialogContent: {
      root: {
        flex: "1 1 auto",
        padding: "0px",
        overflowY: "none",
        "& first-child": {
          paddingTop: "20px !important",
        },
      },
    },
    MuiList: {
      padding: {
        paddingLeft: "8px",
        paddingBottom: "8px",
        paddingTop: "8px",
      },
    },
    MuiFilledInput: {
      root: {
        "&.Mui-focused": {
          backgroundColor: "#3F3F3F !important",
        },
        // position: "relative",
        backgroundColor: "#3F3F3F",
        borderRadius: "5px",
        height: "48px",
        "&:hover": {
          backgroundColor: "#3F3F3F",
        },
        "&:active": {
          backgroundColor: "#3F3F3F !important",
        },
      },
      underline: {
        "&:before": {
          left: "0px",
          right: "0px",
          bottom: "0px",
          content: "00a0",
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "none",
          pointerEvents: "none",
        },
        "&:after": {
          left: "0px",
          right: "0px",
          bottom: "0px",
          content: " ",
          position: "absolute",
          transform: "scaleX(0)",
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          /* border-bottom: 2px solid #898989; */
          pointerEvents: "none",
        },
      },
    },

    MuiDivider: {
      root: {
        border: "none",
        height: "1px",
        margin: "0",
        flexShrink: "0",
        backgroundColor: "#848383",
      },
    },

    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },

    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: "5px",

        border: "1px solid #fff",
      },
      input: {
        padding: "23px 14px",
      },
      inputMarginDense: {
        paddingTop: "23px",
        paddingBottom: "23px",
      },
      inputAdornedEnd: {
        padding: "23px 15px",
        paddingRight: "15px",
      },
      notchedOutline: {
        position: "absolute",
        top: "-6px",
        left: "-1px",
        right: "-1px",
        bottom: "-1px",
        border: "1px solid #ff7d68",
      },

      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },

    MuiPickersToolbar: {
      toolbarLandscape: {
        width: "100%",
        maxWidth: "100%",
        background:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%) !important",
      },
      toolbar: {
        backgroundColor:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        color: "#00FFFF ",
        backgroundColor:
          "linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
      },
    },
    MuiPickersDay: {
      day: {
        color: "white",
        height: "38px",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        // width:'300px !important',
        // maxWidth:'300px !important',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        paddingTop: "20px",
      },
      dayLabel: {
        color: "white",
      },
    },
    MuiPickersBasePicker: {
      containerLandscape: {
        flexDirection: "column",
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      root: {
        color: "#FFFFFF",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#031033",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
        padding: "30px",
        "@media(max-width:767px)": {
          marginTop: "105px",
        },
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "300px !important",
        width: "300px !important",
        padding: "30px !important",

        "@media(max-width:600px)": {
          padding: "20px !important",
        },
        "@media(max-width:767px)": {
          maxWidth: "310px !important",
        },
      },
    },
    MuiDialogActions: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },

    MuiIconButton: {
      root: {
        color: "#00FFFF",
      },
    },

    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#FFF",
        height: "0.1876em",
        width: "100%",
        border: "0",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0px",
        background: "none",
        boxSizing: "content-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
        webkitTaphighlightColor: "transparent",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      text: {
        padding: "7px 16px",
      },
      textPrimary: {
        color: "#00FFFF !important",
        "&:hover": {
          background: "#fff",
          backgroundColor: "#fff !important",
        },
      },
      root: {},
      containedSecondary: {
        background: "linear-gradient(0deg, #00FFFF, #00FFFF), #4D4D4D",
        border: "1px solid #5F5D5D",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        color: "#ffffff",
        fontSize: "15px",
        fontWeight: "500",
        height: "40px",
        lineHeight: " 21px",
        fontFamily: "'Orbitron'",
        padding: "25px 25px",
        "@media(max-width:767px)": {
          height: "auto",
          padding: "13px 15px",
        },
        "&:hover": {
          background:
            "radial-gradient(185.51% 95.21% at 50% 50%, #131313 0%, rgba(19, 19, 19, 0) 100%), #4D4D4D",
          border: "1px solid #5F5D5D",
          color: "#fff !important",
        },
      },
      containedPrimary: {
        background:
          "radial-gradient(185.51% 95.21% at 50% 50%, #131313 0%, rgba(19, 19, 19, 0) 100%), #4D4D4D",
        border: "1px solid #5F5D5D",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        color: "#FFFFFF",
        fontSize: "15px",
        fontWeight: "500",
        height: "40px",
        lineHeight: " 21px",
        padding: "25px 35px",
        marginRight: "10px",
        "@media(max-width:767px)": {
          height: "auto",
          padding: "13px 15px",
        },
        "&:hover": {
          background: "linear-gradient(0deg, #00FFFF, #00FFFF), #4D4D4D",
          border: "1px solid #5F5D5D",
          color: "#000",
        },
      },
      contained: {
        borderRadius: "10px",
        color: "#ffffff",
        fontWeight: 600,
        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "#ffffff",
          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "10px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#ffffff",
          border: "2px solid #ffffff",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiTypography: {
      subtitle1: {
        color: "#fff",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
