export default {
  h1: {
    fontWeight: 700,
    fontSize: 45,
    fontFamily: "'Montserrat Alternates', sans-serif",
  },
  h2: {
    fontWeight: 700,
    fontSize: 38,
    fontFamily: "'Oswald', sans-serif",
    "@media(max-width:767px)": {
      fontSize: 30,
    },
  },
  h3: {
    fontWeight: 700,
    fontSize: 30,
    fontFamily: "'Orbitron', sans-serif",
    "@media(max-width:767px)": {
      fontSize: 20,
    },
  },
  h4: {
    fontWeight: 700,
    fontSize: 30,
    fontFamily: "'Orbitron', sans-serif",
  },
  h5: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "'Orbitron', sans-serif",
  },
  h6: {
    fontWeight: 400,
    fontSize: 16,
    fontFamily: "'Orbitron', sans-serif",
    '@media(max-width: 767px)': {
      fontSize: 14, 
    }
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 10,
    fontFamily: "'Orbitron', sans-serif",
  },
  body1: {
    fontSize: 14,
    fontFamily: "'Orbitron', sans-serif",
  },
  body2: {
    lineHeight: "23px",
    color: "#B1AFAF",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
  },
};
