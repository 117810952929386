import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/logo/logo.png"
      alt="Logo"
      {...props}
      style={{ width: "100px" }}
    />
  );
};

export default Logo;
