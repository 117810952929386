import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Container,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

import { sortAddress } from "src/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .closeButton": {
      position: "absolute",
      top: "-35px",
      right: "-15px",
      "& svg": {
        color: "#FFFFFF",
      },
    },
    "& .mainBox": {
      "& h4": {
        fontSize: "23px",
        color: "#FFFFFF",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
          fontSize: "18px",
        },
      },
      "& .metamaskButton": {
        "& Button": {
          "& figure": {
            margin: "0px",
            "& img": {
              width: "25px",
            },
          },
        },
      },
    },
  },

  learnMoreButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& Button": {
      color: "#3bc1ce",
      display: "flex",
      alignItems: "center",
    },
    "& a": {
      fontSize: "18px",
      color: "#00FFFF",
      textShadow: "rgb(50 235 251) 0px 0px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
      },
    },
  },
  connecttionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "300px",
    border: "1px solid #00FFFF",
    padding: "8px",
    cursor: "pointer",
  },
}));

function MetaMaskPopup({ user, account, setOpen }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        {/* <IconButton className="closeButton">
                    <CloseIcon />
                </IconButton> */}
        <Box className="mainBox">
          <Typography variant="h4">
            {account ? "Your wallet" : "You Need an Account to Play"}
          </Typography>
        </Box>
        {!account && (
          <Box align="center" mt={2} mb={2}>
            <Typography variant="body2">
              Connect or setup new account with:
            </Typography>
          </Box>
        )}

        <Box className="metamaskButton" align="center" mt={4}>
          {account ? (
            <Box
              className={classes.connecttionBox}
              onClick={() => user.disconnectWallet()}
            >
              <Typography variant="h6">
                Disconnect {sortAddress(account)}
              </Typography>
            </Box>
          ) : (
            <Box
              className={classes.connecttionBox}
              onClick={() => {
                if (user.connectWallet) {
                  user.connectWallet();
                  if (setOpen) {
                    setOpen(false);
                  }
                }
              }}
            >
              <img
                src="images/Metamask.png"
                style={{ width: "31px", marginRight: "11px" }}
              />
              <Typography variant="h6">METAMASK</Typography>
            </Box>
          )}
        </Box>
        {!account && (
          <Box className={classes.learnMoreButtons} align="center" mt={3}>
            <Button
              target="_blank"
              href="https://www.youtube.com/playlist?list=PL37ZVnwpeshFqN5dcZ704lxI3F5iHDYkl"
            >
              Learn More <ChevronRightIcon />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}

export default MetaMaskPopup;
