/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, Link, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { GiToken, GiTrade } from "react-icons/gi";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { RiCalendarEventLine } from "react-icons/ri";
import { MdOutlineMapsHomeWork, MdDashboard } from "react-icons/md";
import { AiFillMacCommand } from "react-icons/ai";
import { TbSocial } from "react-icons/tb";
import { FaArtstation } from "react-icons/fa6";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ConfirmationDialogBox from "src/component/ConfirmationDialogBox";
import { UserContext } from "src/context/User";
import { BsMicrosoftTeams } from "react-icons/bs";
import { ImNewspaper } from "react-icons/im";


const sections = [
  {
    items: [
      {
        title: "Home",
        icon: MdOutlineMapsHomeWork,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: MdDashboard,
        href: "/dashboard",
      },
      {
        title: "Event-Management",
        icon: RiCalendarEventLine,
        href: "/eventmanagement",
      },
      {
        title: "Practice-Management",
        icon: AiFillMacCommand,
        href: "/practice-management",
      },
      {
        title: "Garage-Management",
        icon: GiToken,
        href: "/garage-management",
      },
      {
        title: "User-Management",
        icon: PeopleIcon,
        href: "./usermanagemant",
      },
      {
        title: "Gallery-Management",
        icon: RecentActorsIcon,
        href: "/gallarymanagement",
      },
      {
        title: "Partners-Management",
        icon: SupervisorAccountIcon,
        href: "/partnersmanagement",
      },
      {
        title: "Team-Management",
        icon: BsMicrosoftTeams,
        href: "./team-management",
      },
      // {
      //   title: "Circuit-Management",
      //   icon: GiCircuitry,
      //   href: "/Circuitmanagement",
      // },
      {
        title: "Static-Management",
        icon: FaArtstation,
        href: "/static-management",
      },
      {
        title: "Social-Management",
        icon: TbSocial,
        href: "/social-management",
      },
      {
        title: "Newsletter",
        icon: ImNewspaper,
        href: "/newsletter",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#242538",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "rgb(3, 10, 32)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    display: "flex",
    alignItems: "center", // Align items vertically
    paddingLeft: theme.spacing(2), // Add padding similar to other icons
    borderRadius: theme.shape.borderRadius,
    color: "#FFFFFF99",
    fontSize: "14px", // Adjust font size for consistency
    width: "100%", // Make the button take the full width
    textTransform: "none", // Keep the text case unchanged
    justifyContent: "flex-start",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const user = useContext(UserContext);
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box padding={0} className={classes.logoicon}>
          <Link to="/">
            <Logo
              width="180"
              style={{
                width: "90px",
                cursor: "pointer",
              }}
            />
          </Link>
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "16.5px",
            color: "#f5f7fac9",
          }}
        >
          <ExitToAppIcon
            style={{
              marginRight: "6px",
              color: "red",
            }}
          />
          &nbsp; Logout
        </Button>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <ConfirmationDialogBox
        openModal={isLogout}
        handleClose={() => setIsLogout(false)}
        heading="Logout Admin"
        description={`Are you sure, you want to logout admin?`}
        HandleConfirm={() => {
          window.sessionStorage.removeItem("admin_token");
          user.setAdminIsLogin(false);
          history.push("/");
        }}
        isLoading={false}
      />
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
