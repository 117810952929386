import React from "react";
import { makeStyles, Box, Typography, Grid } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  root: {
    // overflowY: "scroll",
    // height: "670px",
    "& .mainBox": {
      "& .TopBox": {
        paddingBottom: "30px",

        "& h3": {
          color: "#00FFFF",
          [theme.breakpoints.only("xs")]: {
            fontSize: "15px",
          },
        },
      },
      "& .personaldata": {
        border: "1px solid #00FFFF",
        borderRadius: "5px 5px 0px 0px",
        padding: "20px",
        "& .heading": {
          "& h4": {
            fontSize: "23px",
            [theme.breakpoints.only("xs")]: {
              fontSize: "16px",
              lineHeight: "19px",
            },
          },
        },
      },
      "& .Contactinformation": {
        padding: "20px 0px",
        "& .headingBox": {
          "& h4": {
            fontSize: "16px",
          },
        },
        "& .mainBox": {
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "20px",
          "& .mainContent": {
            marginLeft: "15px",
            "& h5": {
              fontWeight: "bold",
              lineHeight: "25px",
            },
            "& h6": {
              fontWeight: "bold",
              lineHeight: "25px",
            },
            "& p": {
              fontWeight: "300",
              lineHeight: "22px",
            },
          },
        },
      },
      "& .baseSection": {
        paddingTop: "15px",
        "& .textBox1": {
          padding: "10px 0px",
          "& p": {
            padding: "5px 0px",
          },
        },
        "& .textBox2": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
      },
    },
  },
  mainBox: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",

    "& svg": {
      color: "#00FFFF",
    },
    "& .mainContent": {
      marginLeft: "15px",
      "& h5": {
        fontWeight: "bold",
        lineHeight: "25px",
      },
      "& h6": {
        fontWeight: "bold",
        lineHeight: "25px",
      },
      "& p": {
        fontWeight: "300",
        lineHeight: "22px",
      },
    },
  },
}));

function PrivacyAndPolicy() {
  const classes = useStyles();
  return (
    <>
      <Box className="scrollbar">
        <Box className={classes.root}>
          <Box className="mainBox">
            <Box className="TopBox">
              <Typography variant="h3">Privacy Policy of Racing CAR</Typography>
            </Box>
            <Box className="personaldata">
              <Box className="heading">
                <Typography variant="h4">
                  Personal Data processed for the following purposes and using
                  the following services:
                </Typography>
              </Box>
              <Box mt={4} mb={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <AccountBalanceIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">Analytics</Typography> */}
                        <Typography variant="h6">Google Analytics</Typography>
                        <Typography variant="body2">
                          Personal Data: Tracker; Usage Data
                        </Typography>
                        {/* <Typography variant="h5">Analytics</Typography> */}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <DraftsOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">Contacting the User</Typography> */}
                        <Typography variant="h6">
                          Mailing list or newsletter
                        </Typography>
                        <Typography variant="body2">
                          Personal Data: email address
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <FileCopyOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">
                        Interaction with data collection platforms and other
                        third parties.
                      </Typography> */}
                        <Typography variant="h5">Service Providers</Typography>
                        <Typography variant="body2">
                          {" "}
                          Personal Data: email address
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <FileCopyOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">
                        Managing contacts and sending messages
                      </Typography> */}
                        <Typography variant="h6">MailerLite</Typography>
                        <Typography variant="body2">
                          {" "}
                          Personal Data: email address
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <FileCopyOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">
                        Managing support and contact requests
                      </Typography> */}
                        <Typography variant="h6">
                          Animoca Brands Limited Privacy Policy
                        </Typography>
                        <Typography variant="body2">
                          {" "}
                          The Defiant Metaverse is a place where players can
                          purchase custom equipment for their avatars, interact
                          with other members of the community, gain access to
                          alpha knowledge, and much more.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <FileCopyOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">
                        Managing support and contact requests
                      </Typography> */}
                        <Typography variant="h6">
                          Animoca Brands Limited Privacy Policy
                        </Typography>
                        <Typography variant="body2">
                          {" "}
                          The Defiant Metaverse is a place where players can
                          purchase custom equipment for their avatars, interact
                          with other members of the community, gain access to
                          alpha knowledge, and much more.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.mainBox}>
                      <Box className="icons">
                        <FileCopyOutlinedIcon />
                      </Box>
                      <Box className="mainContent">
                        {/* <Typography variant="h5">Tag Management</Typography> */}
                        <Typography variant="h6">Google Tag Manager</Typography>
                        <Typography variant="body2">
                          The Defiant Metaverse is a place where players can
                          purchase custom equipment for their avatars, interact
                          with other members of the community, gain access to
                          alpha knowledge, and much more.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* <Box className="Contactinformation">
            <Box className="headingBox">
              <Typography variant="h4">Contact information</Typography>
            </Box>
            <Box className="mainBox">
              <Box className="icons">
                <PersonIcon />
              </Box>
              <Box className="mainContent">
                <Box>
                  <Typography variant="h6">
                    Owner and Data Controller
                  </Typography>
                  <Typography variant="body1">REVV Racing</Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="h6">Owner contact email:</Typography>
                  <Typography variant="body1">
                    revvracing@animocabrands.com
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}
            {/* <Box className="baseSection">
            <Box className="textBox1">
              <Typography variant="body1">
                Latest update: July 30, 2021
              </Typography>
              <Typography variant="body1">
                iubenda hosts this content and only collects the Personal Data
                strictly necessary for it to be provided.
              </Typography>
            </Box>
            <Box className="textBox2">
              <Typography variant="body1">
                Show the complete Privacy Policy
              </Typography>
            </Box>
          </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PrivacyAndPolicy;
